.landing{
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    padding-bottom: 5rem;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}
.sideDrawer{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
}