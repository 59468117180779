
.communityStatus{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    position: relative;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.same{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    & *{
        font-weight: 500;
    }
}
.heading{
    font-size: 18px;
    font-weight: 700;
}
.container{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    align-self: stretch !important;
}