.Beta{
    display: flex;
    padding: 60px 0px 100px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    & .top{
        display: flex;  
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        & .succession{
            display: flex;
            padding: 48px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 100px;
            background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%);
        }
    }
}