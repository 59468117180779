
.image{
    display: flex;
    width: 100px;
    height: 100px;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #FFF;
    position: relative;
}
.Cameraupload{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 14px;
    bottom: -5px;
    border-radius: 100px;
    border: 1px solid #FFF;
    background: #FFF;
}