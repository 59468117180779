.text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}
.frame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    margin: 0;
    & li{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }
}