.coverImage{
    display: flex;
    width: 100%;
    padding: 32px 48px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dialogBox{
    display: flex;
    width: 430px;
    border-radius: 8px 8px 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 0px;
    width: 100%;
    outline: none;
    & .container{
        display: flex;
        padding: 16px 24px;
        align-items: center;
        align-self: stretch;
        & .text{
            display: flex;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            justify-content: space-between;
        }
    }
    & .upload{
        display: flex;
        padding: 24px 24px 40px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        & .uploadPhoto{
            display: flex;
            align-items: flex-start;
            align-self: stretch;
        }
    }
}
.subText{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}