.search{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 0px 24px;
    overflow: hidden;
    gap: 16px;
}
.arrow{
    display: flex;
    padding: 8px 0px 0px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
