.parent{
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.logo{
    width: 238px;
    height: 79.056px;
}
.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;

}
.searchForm{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px 6px 20px;
    border-radius: 100px;
    gap: 8px;
    box-shadow: none;
    align-self: stretch;
}