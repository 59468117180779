.dialog {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.communityStatus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.details {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
}
.basicsType{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.ageGen{
  display: flex;
  align-items: center;
  gap: 8px;
}
.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}