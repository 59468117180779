.subStatus{
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 5rem;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    padding: 24px;
}
.details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 0 0;
    align-self: stretch;
    & .content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;    
    }
}