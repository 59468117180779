.parent{
    gap: 8px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    /* width: 100%; */
    z-index: 1000;
    display: flex;
    justify-content: center;
}
.container{
    /* background:#FFF; */
    /* flex: 1 0 0; */
    align-self: stretch;
    opacity: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    height: 100%;
}
.frame{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 109px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    & .content{
        display: flex;
        flex-direction: column;
        padding: 8px 0px;
        align-self: stretch;
        gap: 16px;
    }
}