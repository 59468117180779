.skillsCommunityStatus{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    position: relative;
}
.skillsItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}
.tags{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
}
.report{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 16px;
    top: 16px;
    & button{
        border-radius: 2px;
        padding: 2px 6px;
        min-width: auto;
        min-height: auto;
        text-transform: capitalize;
    }
}
.heading{
    font-size: 18px;
    font-weight: 500;
}