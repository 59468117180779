.community {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 6px;
}

.tags {
  display: flex;
  align-items: center;
  gap: 8px;
}

.communityStatus {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 6px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.same {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  & .detail, & .title{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & .container{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    align-self: stretch !important;
  }
}