.card{
    display: flex;
    align-items: flex-start;
    align-content: center;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;
}
.container{
    width: calc(50% - 8px);
    /* min-width: 160px; */
}
.loading{
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
