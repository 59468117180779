.parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
}
.basicLogo{
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;    
    border-radius: 12px;
    background: linear-gradient(95deg, #9B1CF6 2.49%, #FF8033 100%);

}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.logo{
    width: 233.839px;
    height: 79.056px;
    flex-shrink: 0;
}
.stepper{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;
}