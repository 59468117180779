.about {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  position: relative;
}
.up {
  position: absolute;
  bottom: 640px;
  left: 16px;
  z-index: 1000;
  width: calc(100% - 32px);
  text-transform: capitalize;
  box-shadow: none;
  display: flex;
}
.loading{
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
