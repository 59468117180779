.parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    min-height: 100vh;
    padding-bottom: 5rem;
}
.cardDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
}
.myCardDetails{
    display: flex;
    padding-top: 72px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%);
}
.userDetails{
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.details{
    display: flex;
    align-items: center;
    gap: 16px;
}
.image{
    display: flex;
    width: 100px;
    height: 100px;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #FFF;
}
.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
}