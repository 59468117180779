.gredient{
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.register{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    align-self: stretch;
}
.logoContainer{
    display: flex;
    padding: 1.5rem 24px 20px 24px;
    /* padding: 24px 10px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    @media(max-height: 667px) {
        padding: 1.5rem 24px 20px 24px;
    }
}
.logo{
    width: 220px;
    height: 55px;
}

.buttonContainer{
    display: flex;
    padding: 60px 24px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    /* flex: 1 0 0; */
    align-self: stretch;
}
.button{
    text-transform: uppercase;
    padding: 8px 24px 8px 8px !important;
    border-radius: 4px;
    display: flex;
    gap: 16px;
    /* justify-content:flex-start !important; */
    width: 290px;
}
/* .crads{
    margin-top: 10px;
} */
.card{
    display: flex;
    /* width: 400px; */
    height: 51.4vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 1px 3px 0px rgba(163, 65, 144, 0.30), 0px 4px 8px 3px rgba(98, 72, 93, 0.30);
    /* background: #FFF; */
    /* margin-right: 10px; */
    cursor: grab;
    & img{
        width: 100px;
        border: 12px solid;
        border-image: linear-gradient(190deg, #EA0AFB, #DC0000, #FFA328);
        border-image-slice: 1;
    }
}
.container{
    /* display: flex; */
    padding: 0px 24px !important;

    /* flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch; */
}