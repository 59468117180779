.discovery{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;    
}
.parent{
    padding: 1.5rem 2.5rem;
    @media (max-width: 430px) and (max-height: 667px) {
        padding: 1.5rem 1rem;
    }
}
.logo{
    display: flex;
    /* padding: 0rem 24px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.basics{
    display: flex;
    padding-top: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    @media (max-width: 430px) and (max-height: 667px) {
        padding-top: 1.5rem;    
        gap: 1rem;
    }
}
.iconContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    & .icon{
        display: flex;
        padding: 3rem;
        align-items: flex-start;
        gap: 8px;
        border-radius: 100px;
        background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%);
        @media (max-width: 430px) and (max-height: 667px) {
            padding: 2.5rem;
        }
    }
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    @media (max-width: 430px) and (max-height: 667px) {
        gap: 1rem;
    }
    & .text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: 333px;
        @media (max-width: 430px) and (max-height: 667px) {    
            gap: 0.5rem;
            width: auto;
        }
    }
}