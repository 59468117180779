.parent{
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.logo{
    width: 238px;
    height: 79.056px;
}
.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}