.items{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    padding: 16px 0px;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;
}
.CreatorCard{
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 6px;
    box-sizing: border-box;
    width: calc(50% - 8px);
}
.img{
    width: 140px;
    height: 140px;
    border-radius: 140px;
    & img{
        border-radius: 100%;
        object-fit: cover;
    }
}
.text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}