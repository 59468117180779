.crads{
    margin-top: 10px;
}

.loading{
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}