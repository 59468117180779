.skillsCommunityStatus{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    position: relative;
}
.skillsItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    
}
.tags{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    word-wrap: break-word;
}
.heading{
    font-size: 18px;
    font-weight: 500;
}

