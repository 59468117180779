.jobSubmission{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    height: 100vh;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-radius: 8px;
    padding: 40px 40px;
}
.icon{
    display: flex;
    padding: 48px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 100px;
    background: linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%);
}
.text{
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 300px;
}