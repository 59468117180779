.landing{
    height: 100vh;
    width:100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 1.5rem 5rem ;
    flex: 1 0 0 ;
}
.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-bottom: 24px;
    align-self: stretch;
    text-align: center;
}
.button{
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}