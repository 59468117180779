.mySkills {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  align-self: stretch;
}
.loading{
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;

}