.skillsCommunityStatus{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 6px;
    position: relative;
    width: 100%;
}
.skillsItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%;
}
.tags{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;

}
.edit{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 16px;
    top: 16px;
}



