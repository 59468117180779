.help {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.loading{
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}