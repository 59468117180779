.editSkill{
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.communityStatus{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
}
.title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.inputField{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}