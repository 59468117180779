.top {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.profile {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
}
.image {
  border-radius: 32px;
}
.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  & h6 {
    font-size: 14px;
    font-weight: 500;
  }
  & p {
    font-size: 10px;
    font-weight: 400;
  }
}
.Shouts {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100%;
}
