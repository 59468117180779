.projectForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 100%;    
}
.button{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}