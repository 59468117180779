.home{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.container{
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    margin-bottom: 40px;
}
