.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
}

.loadingDots {
    display: flex;
}

.dot{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #8c20b3; /* Purple color */
    margin: 0 10px;
    animation: pulse 1.5s infinite ease-in-out;
}

#dot2 {
    animation-delay: 0.5s;
}

#dot3 {
    animation-delay: 1s;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.6);
    }
}