.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.skillNum {
  display: flex;
  width: 84px;
  height: 84px;
  padding: 29px 26px 29.4px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.text {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h4{
    width: 100px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.addSkill {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
