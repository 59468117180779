.parent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background: #FFF;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.container{
    flex: 1 0 0;
    align-self: stretch;
    opacity: 0.8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    height: 100%;
}
.frame{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 109px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(45, 45, 50, 0.42) 100%);
    & .content{
        display: flex;
        padding: 8px 0px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: #fff;
    }
}
.crop{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}