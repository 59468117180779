.newClassName{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.tab{
    display: flex;
    padding: 4px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.text{
    display: flex;
    padding: 60px 0px 100px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
}
