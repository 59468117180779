.basicLogo{
    display: flex;
    padding: 1.5rem 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;    
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px !important;
    text-align: center;
}
.logo{
    width: 280.839px;
    height: 79.056px;
    flex-shrink: 0;

}
.beta{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}