.myCardDetails {
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: linear-gradient(
    160deg,
    #0f5ff7 9.58%,
    #9373ff 51.07%,
    #e81cff 93.44%
  );
}
.image__parent {
  position: relative;
}
.image__parent > button {
  position: absolute;
  color: black;
  top: -1.2rem;
  left: -1.5rem;
  z-index: 10;
  text-transform: capitalize;
  font-size: 0.6rem;
}
.userDetails {
  display: flex;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.details {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
}
.image {
  display: flex;
  width: 100px;
  height: 100px;
  padding: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #fff;
  position: relative;
}
.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.shouts {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1000px;
}
