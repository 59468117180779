.content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}
.inputField{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}
.options{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.country{
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}