.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    padding-bottom: 5rem;
    min-height: 100vh;
}
.arrow{
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}